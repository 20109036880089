import React from 'react';
import { useState } from 'react';
import './index.scss';
import { PAGES } from '../../constants/options';
import { useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Cube from '../cube';

library.add(faSearch);

const TopNav = (props) => {
  const {
    isShow,
    handleClickCube,
    handleClickGallery,
    handleClickContact,
    handleClickGuidedTour,
    handleClickLogo,
    handleClickInteractiveTour,
    handleClickVirtualGallery,
  } = props;
  const [isCollapse, setIsCollapse] = useState(true);
  const [firstShow, setFirstShow] = useState(true);
  const [isDragDown, setIsDragDown] = useState(false);
  const [isHideCubeOnDrag, setIsHideCubeOnDrag] = useState(true);
  const pageActive = useSelector((state) => state.home.page);
  const [linksDisabled, setLinksDisabled] = useState(true);

  const setClickTimer = (localCollapsed) => {
    if (localCollapsed) {
      setLinksDisabled(true);
      return;
    }
    setTimeout(() => {
      setLinksDisabled(false);
    }, 700);
  }

  const onClickCube = () => {
    handleClickCube();
    setIsCollapse(false);
    setClickTimer(false);
  };

  useEffect(() => {
    isShow && onClickCube();
  }, [isShow]);

  const startDrag = () => {
    setIsHideCubeOnDrag(false);
  };

  const endDrag = () => {
    setIsHideCubeOnDrag(true);
    setIsDragDown(true);
    setFirstShow(false);
    setIsCollapse(!isCollapse);
    setClickTimer(!isCollapse);
  };

  const isActive = (menu) => {
    return pageActive === menu ? 'active' : '';
  };

  return (
    <div
      className={`wrap-top-nav
      ${isCollapse ? 'hide' : 'show'}`}
    >
      <div className={`container nav-menu ${linksDisabled && 'disabled'}`}>
        {/* <div className='wrap-logo' onClick={handleClickLogo}>
          <img className='logo' src="/images/logo.svg" alt="" />
        </div> */}
        <ul className="left">
          <li className={isActive(PAGES.INTERACTIVE_TOUR)} onClick={handleClickInteractiveTour}>
            <span>INTERACTIVE MAP</span>
          </li>
          <li className={isActive(PAGES.GUIDED_TOUR_PAGE)} onClick={handleClickGuidedTour}>
            <span>GUIDED VIDEO</span>
          </li>
        </ul>
        <ul className="nav-cube">
          <div className='logo' onClick={handleClickLogo}>
            <img className='' src="/images/logo.svg" alt="" />
          </div>
          {/* {isShow && <Cube
            draggable={true}
            active={!isCollapse}
            onDragStart={startDrag}
            onDragEnd={isShow && endDrag}
            onClick={isShow && onClickCube}
            onDoubleClick={() => isShow && handleClickCube()}
          />} */}
        </ul>
        <ul className="right">
          <li className={isActive(PAGES.GALLERY_PAGE)} onClick={handleClickGallery}>
            <span>IMAGE GALLERY</span>
          </li>
          {/* <li className={isActive(PAGES.CONTACT_PAGE)} onClick={handleClickContact}>
            <span>ENQUIRE NOW</span>
          </li> */}
          <li className={isActive(PAGES.VIRTUAL_GALLERY)} onClick={handleClickVirtualGallery}>
            <span>360 TOUR GALLERY</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopNav;
